exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-beesy-tsx": () => import("./../../../src/pages/beesy.tsx" /* webpackChunkName: "component---src-pages-beesy-tsx" */),
  "component---src-pages-cancellation-refund-tsx": () => import("./../../../src/pages/cancellation_refund.tsx" /* webpackChunkName: "component---src-pages-cancellation-refund-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy_policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-shipping-policy-tsx": () => import("./../../../src/pages/shipping_policy.tsx" /* webpackChunkName: "component---src-pages-shipping-policy-tsx" */),
  "component---src-pages-terms-conditions-tsx": () => import("./../../../src/pages/terms_conditions.tsx" /* webpackChunkName: "component---src-pages-terms-conditions-tsx" */)
}

